/* Admin Row Styles */
.admin-row {
    background-color: #e0f7fa; /* Light blue */
}

/* Terminated Row Styles */
body .MuiDataGrid-row.admin-row:hover {
    background-color: #99d5ec; /* Saturate the light blue above. */
}

/* User Row Styles */
.current-user-row {
    background-color: #fce4ec; /* Light pink */
}

/* Terminated Row Styles */
body .MuiDataGrid-row.Mui-selected.terminated,
body .MuiDataGrid-row.terminated,
.MuiDataGrid-row.current-user-row.terminated,
.MuiDataGrid-row.admin-row.terminated {
    background-color: #fd5050; /* Red. */
}

/* Make the row color is black if the previous row has the terminated class. */
body .MuiDataGrid-row.terminated + .MuiDataGrid-row.terminated {
    --rowBorderColor: black;
}

body .MuiDataGrid-row.Mui-selected.terminated:hover,
body .MuiDataGrid-row.terminated:hover,
.MuiDataGrid-row.current-user-row.terminated:hover,
.MuiDataGrid-row.admin-row.terminated:hover {
    background-color: #fd3535; /* Saturate the red above. */
}