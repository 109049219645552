*{
    margin: 0;
    padding: 0;
}

html,
body,
#root {
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}


@media screen and (min-width: 900px) {
    /* Make the toast container bigger on larger displays. */
    body .Toastify__toast-container {
        width: 640px;
        max-width: fit-content;
    }
}