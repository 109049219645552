/* Ensure the flag is appropriately sized */
.react-phone-number-input__icon {
    width: 20px !important; /* Adjust the width as needed */
    height: auto !important;
}

/* Ensure the dropdown menu flag is also appropriately sized */
.react-phone-number-input__country-select-arrow {
    width: 20px !important; /* Adjust the width as needed */
    height: auto !important;
}

/* Adjust the input field padding to fit the new flag size */
.react-phone-number-input__input {
    padding-left: 35px !important; /* Adjust the padding as needed */
}